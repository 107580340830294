<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-row>
      <v-col md="2">
        <v-select
          :disabled="dataLoading"
          :loading="dataLoading"
          :items="statusList"
          v-model="filter.status"
          hide-details
          flat
          solo
          dense
          label="Status"
          color="cyan"
          item-color="cyan"
          class="pt-0 mt-0"
          item-text="text"
          item-value="value"
          v-on:change="getAllQuotations"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
              <!-- <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-chip :color="item.color" :text-color="item.textcolor">
                <template v-if="item.value == 'all'">{{
                  item.all_ltx_contracts
                }}</template>
                <template v-else>{{ item.contract_status_count }}</template>
              </v-chip>
            </v-list-item-action>
          </template>
        </v-select>
      </v-col>
      <v-col md="2">
        <v-text-field
          v-model.trim="filter.search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :disabled="dataLoading"
          :loading="dataLoading"
          color="cyan"
          @keydown.enter="getAllQuotations"
          @keydown.tab="getAllQuotations"
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              label="Dates Range"
              hide-details
              solo
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:click:clear="clearFilter('dates')"
              clearable
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="dataLoading"
            :loading="dataLoading"
            v-on:change="getAllQuotations"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="2">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:click="getAllQuotations"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
        <template v-if="false && type == 'project' && detail.status < 4">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="customerPersonDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Quotation</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
    <ListingTable
      class="internal-listing-table"
      :columnCount="6"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Contract #</th>
            <th class="simple-table-th" width="300">Title / Company address</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="100">No. of Visits</th>
            <th class="simple-table-th" width="100">Status</th>
            <th class="simple-table-th" width="200">Amount</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              class="alternate-listing-row"
              v-for="(row, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('quotation.detail', {
                    params: { id: row.id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <div class="d-inline-grid" style="position: relative">
                  <Barcode
                    route="contract.detail"
                    :barcode="row.barcode"
                    :id="row.id"
                  ></Barcode>
                  <Barcode
                    color="orange"
                    route="quotation.detail"
                    :barcode="row.quotation_barcode"
                    :id="row.quotation_id"
                  ></Barcode>

                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        style="position: absolute; top: 7px; right: -25px"
                        v-if="row?.has_tool == 1"
                        color="green darken-4"
                        small
                        >mdi-tools</v-icon
                      >
                    </template>
                    <span>Tools</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Title: </b>{{ row.title }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b
                      >Visit Count:
                      <template v-if="row && row.visit_count > 0">
                        <span
                          class="font-size-16 ml-1 count-radius cyan white--text"
                          >{{ row.visit_count }}</span
                        >
                      </template>
                      <span
                        v-else
                        style="
                          background-color: #93959f !important;
                          color: white;
                        "
                        class="ml-1 count-radius white-text"
                        >0</span
                      ></b
                    >
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis-two-line"
                    v-if="row.billing"
                  >
                    <b>Company Address: </b>
                    <template v-if="row.billing.property_address"
                      >{{ row.billing.property_address }},
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Started At: </b>
                    <template v-if="row?.started_at">
                      {{ formatDate(row?.started_at) }}
                    </template>
                    <em class="text-muted" v-else> no started at</em>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Finished At: </b>
                    <template v-if="row?.finished_at">
                      {{ formatDate(row?.finished_at) }}
                    </template>
                    <em class="text-muted" v-else> no finished at</em>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b
                      ><span
                        class="font-size-18 mr-1 count-radius cyan white--text"
                        >{{ row.visit_count }}</span
                      >
                      Total</b
                    >
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis green--text text--darken-4"
                  >
                    <b
                      ><span
                        class="font-size-18 mr-1 count-radius green white--text"
                        >{{ row.completed_count }}</span
                      >
                      Completed</b
                    >
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis yellow--text text--darken-4"
                  >
                    <b
                      ><span
                        class="font-size-18 mr-1 count-radius yellow darken-4 white--text"
                        >{{ row.pending_count }}</span
                      >
                      Pending</b
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis red--text">
                    <b
                      ><span
                        class="font-size-18 mr-1 count-radius red white--text"
                        >{{ row.cancelled_count }}</span
                      >
                      Cancelled</b
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="d-flex flex-column flex-wrap justify-center align-start"
                >
                  <v-chip
                    label
                    small
                    :color="row?.qt_status_color"
                    :text-color="row?.qt_status_textcolor"
                    class="ml-2 text-uppercase font-weight-600"
                  >
                    {{ row?.qt_status_text }}
                  </v-chip>
                  <v-chip
                    v-if="row?.invoice_converted == 1"
                    label
                    small
                    color="green"
                    text-color="white"
                    class="my-2 ml-2 text-uppercase font-weight-600"
                    >Converted to Invoice
                  </v-chip>
                  <v-chip
                    v-if="row?.visit_converted == 1"
                    label
                    small
                    color="orange"
                    text-color="white"
                    class="ml-2 text-uppercase font-weight-600"
                    >Converted to Visit
                  </v-chip>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sub Total: </b>
                    {{ $accountingJS.formatMoney(row.sub_total) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Tax: </b>
                    {{ $accountingJS.formatMoney(row.tax_amount) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total: </b>
                    {{ $accountingJS.formatMoney(row.total) }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="6">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no contract at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      internal
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
      class="internal-footer"
    ></ListingFooter>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data() {
    return {
      parent: 0,
      customer: 0,
      customerPerson: 0,
      customerBilling: 0,
      totalPages: 0,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      customerPersonDialog: false,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }
          this.getAllQuotations();
        }
      },
    },
  },
  components: {
    Barcode,
    CustomerPersonDialog,
    ListingTable,
    ListingFooter,
  },
  computed: {
    ...mapGetters(["contractRecurringScheduleTypeList"]),
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
    maintenanceType() {
      return (param) => {
        return this.contractRecurringScheduleTypeList.find(
          (row) => row.value == param
        );
      };
    },
  },
  mounted() {
    PaginationEventBus.$on("update:pagination", (param) => {
      this.currentPage = param;
      this.getAllQuotations();
    });
  },
  methods: {
    resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        let queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling,
        });

        queryObject[_this.type] = _this.parent;

        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: queryObject,
          })
        );
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllQuotations();
    },
    getAllQuotations() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: _this.currentPage,
        status: _this.filter.status || "all",
        "quote-type": "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "contract",
            data: filter,
          })
          .then(({ data }) => {
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.dataRows = data.rows;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
